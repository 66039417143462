import React, {useState, useEffect} from 'react';
import { useToast, Box, Flex, Heading, Button, Icon, Table, Thead, Tr, Th, Td, Text, Tbody, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, FormControl, FormLabel, Input, Select, IconButton } from '@chakra-ui/react';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { RiAddLine, RiCloseLine, RiPencilLine } from 'react-icons/ri';
import { formatDate } from '../utils/format';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import api from '../services/api';

import AuthLayout from './_layouts/AuthLayout';

export default function EditUsers({jwt, config}) {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [backId, setBackId] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const navigation = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()

  async function handleOpen(){
    try{
      const response = await api.post('/bo/list-users', {}, {
          headers: {
          'Authorization': 'Bearer ' + jwt
          }
      });
  
      if(response.data){
        setUsers(response.data);
        onOpen();
      };
      setLoading(false);
    } catch(err) {
        setUsers([]);
        setLoading(true);
    }
  }

  async function handlePermission( id, button, value ){    
    let body = {};
    if(button == 'admin'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "admin": value,
      }
    } else if(button == 'account'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "account": value,
      }
    } else if(button == 'account_approval'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "account_approval": value,
      }
    } else if(button == 'account_fee'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "account_fee": value,
      }
    } else if(button == 'account_workspace'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "account_workspace": value,
      }
    } else if(button == 'users'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "users": value,
      }
    } else if(button == 'users_block'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "users_block": value,
      }
    } else if(button == 'card'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "card": value,
      }
    } else if(button == 'plan'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "plan": value,
      }
    } else if(button == 'accounting'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "accounting": value,
      }
    } else if(button == 'cripto'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "cripto": value,
      }
    } else if(button == 'pos'){
      body = {
        "user_id": id,
        "type": "ADMIN",
        "pos": value,
      }
    }
    setLoading(true);
    try {
      const response = await api.post('/bo/backoffice-config', body, {
        headers: {
          'Authorization': 'Bearer ' + jwt
        }
      });

      if(response.data){
        setLoading(false);
        toast({
          title: 'Backoffice atualizado',
          description: 'Permissão atualizada com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        loadData();
      };
    } catch (error) {
      onClose();
      setLoading(false);
      toast({
        title: 'Erro no cadastro.',
        description: error.response.data.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      loadData();
    }
  };

  async function handleBackoffice(){
    setLoading(true);
    try {
      const response = await api.post('/bo/invite-backoffice-users', {
        "email": email,
        "role": "ADMIN", 
      }, {
        headers: {
          'Authorization': 'Bearer ' + jwt
        }
      });

      if(response.data){        
        onClose();
        setLoading(false);
        toast({
          title: 'Backoffice adicionado',
          description: 'Usuário convidado com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        loadData();
        setEmail('');
      };
    } catch (error) {
      onClose();
      setLoading(false);
      toast({
        title: 'Erro no cadastro.',
        description: error.response.data.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      loadData();
      setEmail('');
    }
  }

  async function loadData(){
    try{
      const response = await api.post('/bo/backoffice-users', {}, {
          headers: {
          'Authorization': 'Bearer ' + jwt
          }
      });
  
      if(response.data){
        setData(response.data);
        console.log(response.data);
      };
      setLoading(false);
    } catch(err) {
        setData([]);
        setLoading(true);
    }
  };
  
  useEffect(() => {
    loadData()
  }, [jwt])

  return (
    <AuthLayout>
      <Box w='100%' flex='1' borderRadius={8} bg='gray.100' p='8' flexDirection='column' mb='20'>
          <Flex mb='8' justify='space-between' align='center'>
            <Heading size='lg' color='#E52A24' fontWeight='normal'>Backoffices Cadastrados</Heading>
            
            <Button
              size='sm'
              fontSize='sm'
              colorScheme='green'
              onClick={onOpen}
              leftIcon={<Icon
              as={RiAddLine}
            />}
            >
            Adicionar novo
            </Button>
          </Flex>


          <Table colorScheme='gray.200'>
              <Thead>
                  <Tr>
                      <Th>Username</Th>
                      <Th>Contas</Th>
                      <Th>Aprovar Contas</Th>
                      <Th>Alterar Taxas</Th>
                      <Th>Alterar Workspace</Th>
                      <Th>Usuários</Th>
                      <Th>Bloquear Usuários</Th>
                      <Th>Cartão</Th>
                      <Th>Planos</Th>
                      <Th>Painel Contabil</Th>
                  </Tr>
              </Thead>
              <Tbody>
                { data.map((e) => {
                  return(
                    <Tr>
                      <Td>
                          <Text fontWeight='bold'>{e.user.username}</Text>
                      </Td>
                      <Td>
                        <IconButton
                          onClick={() => handlePermission(e.user.id, 'account', !e.account)}
                          icon={e.account == true ? <MdOutlineCheckBox/> : <MdOutlineCheckBoxOutlineBlank/>}
                        />
                      </Td>
                      <Td>
                        <IconButton
                          onClick={() => handlePermission(e.user.id, 'account_approval', !e.account_approval)}
                          icon={e.account_approval == true ? <MdOutlineCheckBox/> : <MdOutlineCheckBoxOutlineBlank/>}
                        />
                      </Td>
                      <Td>
                        <IconButton
                          onClick={() => handlePermission(e.user.id, 'account_fee', !e.account_fee)}
                          icon={e.account_fee == true ? <MdOutlineCheckBox/> : <MdOutlineCheckBoxOutlineBlank/>}
                        />
                      </Td>
                      <Td>
                        <IconButton
                          onClick={() => handlePermission(e.user.id, 'account_workspace', !e.account_workspace)}
                          icon={e.account_workspace == true ? <MdOutlineCheckBox/> : <MdOutlineCheckBoxOutlineBlank/>}
                        />
                      </Td>
                      <Td>
                        <IconButton
                          onClick={() => handlePermission(e.user.id, 'users', !e.users)}
                          icon={e.users == true ? <MdOutlineCheckBox/> : <MdOutlineCheckBoxOutlineBlank/>}
                        />
                      </Td>
                      <Td>
                        <IconButton
                          onClick={() => handlePermission(e.user.id, 'users_block', !e.users_block)}
                          icon={e.users_block == true ? <MdOutlineCheckBox/> : <MdOutlineCheckBoxOutlineBlank/>}
                        />
                      </Td>
                      <Td>
                        <IconButton
                          onClick={() => handlePermission(e.user.id, 'card', !e.card)}
                          icon={e.card == true ? <MdOutlineCheckBox/> : <MdOutlineCheckBoxOutlineBlank/>}
                        />
                      </Td>
                      <Td>
                        <IconButton
                          onClick={() => handlePermission(e.user.id, 'plan', !e.plan)}
                          icon={e.plan == true ? <MdOutlineCheckBox/> : <MdOutlineCheckBoxOutlineBlank/>}
                        />
                      </Td>
                      <Td>
                        <IconButton
                          onClick={() => handlePermission(e.user.id, 'accounting', !e.accounting)}
                          icon={e.accounting == true ? <MdOutlineCheckBox/> : <MdOutlineCheckBoxOutlineBlank/>}
                        />
                      </Td>
                    </Tr>                        
                  )
                })}
              </Tbody>
          </Table>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar novo Backoffice</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Coloque o email do usuário</FormLabel>
              <Input borderColor='black' size='md' type='text' value={email} onChange={(e) => setEmail(e.target.value)}/>
              {/* <Select placeholder='usename' value={backId} onChange={(e) => setBackId(e.target.value)}>
                { users.map((e) => {
                  return (
                    <option value={e.id}>{e.username}</option>
                  )
                })}
              </Select> */}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={handleBackoffice}>
              Adicionar
            </Button>
            <Button onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  </AuthLayout>
  )
}
