import React, { useState, useEffect } from 'react';
import { Input, Box, Flex, Skeleton, Heading, SimpleGrid, Button, Icon, Table, Thead, Tr, Th, Td, Text, theme, Tbody, HStack, Tooltip, Image } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import api from '../../services/api';
import { formatValue, formatCompletDate } from '../../utils/format';
import {format, subDays} from 'date-fns';
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";

import AuthLayout from '../_layouts/AuthLayout';

export default function StatementList({jwt, user}) {
    const [data, setData] = useState({});
    const [accountData, setAccountData] = useState([]);
    const [startDate, setStartDate] = useState(format(subDays(Date.now(), 30), 'yyyy-MM-dd'));
    const [endDate, setEndDate] = useState(format(Date.now(), 'yyyy-MM-dd'));
    const [loading, setLoading] = useState(true);

    async function handleAt(){
        loadData();
    };

    async function loadData(){
        try{
            const response = await api.post('/bo/statement', {
                'pageNumber': 1,
                'pageSize': 1000,
                'startDate': startDate,
                'endDate': endDate
            }, {
                headers: {
                'Authorization': 'Bearer ' + jwt
                }
            });
        
            if(response.data){
                setData(response.data);
                setAccountData(response.data.statement);
                console.log(response.data);
            };
            setLoading(false);
        } catch(err) {
            setData({});
            setAccountData([]);
            setLoading(true);
        }
    };
    
    useEffect(() => {
        loadData()
    }, [jwt]);

    return (
        <AuthLayout>
            <Box w='100%' flex='1' borderRadius={8} bg='gray.100' p='8' flexDirection='column' mb='20'>
                <Flex mb='8' justify='space-between' align='center'>
                    <Heading size='lg' color='#E52A24' fontWeight='normal'>Extatro Geral</Heading>
                    <Flex align='end'>
                        <Flex direction='column' align='left' mr='4'>
                            <Text>Data de Inicio</Text>
                            <Input borderColor='black' size='md' type='date' max={endDate} value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                        </Flex>
                        <Flex direction='column' align='left' mr='4'>
                            <Text>Data Fim</Text>
                            <Input borderColor='black' size='md' type='date' min={startDate} value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                        </Flex>                    
                        <Button type='submit' onClick={handleAt} colorScheme='red'>Filtrar</Button>
                    </Flex>
                </Flex>

                <SimpleGrid flex="1" gap="4" minChildWidth="320px" align="flex-start" mb={4}>
                    <Box
                        p="8"
                        bg="gray.200"
                        borderRadius={8}
                        pb="4"
                    >
                        <Flex w="100%" align="center" pb="4">
                            <Text fontSize="lg" mr="1">Saldo Custódia</Text>
                            <Tooltip label="Valor de taxas debitadas." hasArrow placement='top' width='36' fontSize={10}>
                            <InfoOutlineIcon fontSize="sm" color="gray.400"/>
                            </Tooltip>
                        </Flex>
                        <Flex direction="column" py="4">
                            { loading ? <Skeleton height='50px' /> : <Heading size='xl'>{formatValue(data.custody)}</Heading> }
                            <br/>     
                            { loading ? <Skeleton height='10px' /> : <Text fontSize='xl'>Stark: {formatValue(data.custodyStark)}</Text> }     
                        </Flex>
                    </Box>
                    <Box
                        p="8"
                        bg="gray.200"
                        borderRadius={8}
                        pb="4"
                    >
                        <Flex w="100%" align="center" pb="4">
                        <Text fontSize="lg" mr="1">Saldo Workspace</Text>
                        <Tooltip label="Valor de saldo em todas as carteiras do sistema." hasArrow placement='top' width='36' fontSize={10}>
                            <InfoOutlineIcon fontSize="sm" color="gray.400"/>
                        </Tooltip>
                        </Flex>                  
                        <Flex direction="column" py="4">
                            { loading ? <Skeleton height='50px' /> : <Heading size='xl'>{formatValue(data.special)}</Heading> }
                            <br/>     
                            { loading ? <Skeleton height='10px' /> : <Text fontSize='xl'>Stark: {formatValue(data.specialStark)}</Text> }
                        </Flex>
                    </Box>                                     
                </SimpleGrid>

                <Table colorScheme='gray.200'>
                    <Thead>
                        <Tr>
                            <Th></Th>
                            <Th>Descrição / Origem</Th>
                            <Th>Tipo</Th>
                            <Th>Data - Hora</Th>
                            <Th>Valor</Th>
                            <Th>Conta</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        { accountData.map((e) => {
                            return(
                                <Tr>
                                    <Td>
                                        { e.type == 'IN' ? 
                                            <Icon boxSize={6} color={'green'} as={FaChevronCircleDown} />
                                        :
                                            <Icon boxSize={6} color={'red'} as={FaChevronCircleUp} />
                                        }
                                    </Td>
                                    <Td>
                                        <Text fontWeight='bold'>{e.description}</Text>
                                    </Td>
                                    <Td>
                                        {e.method}
                                    </Td>
                                    <Td>
                                        {formatCompletDate(e.date) }
                                    </Td>
                                    <Td>
                                        {formatValue(e.amount)}
                                    </Td>                                                             
                                    <Td>
                                        <Text fontWeight='bold'>{e.account.company_name}</Text>
                                        {e.account.account_token}
                                    </Td>                                        
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </Box>
        </AuthLayout>
    )
}
